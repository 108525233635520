import React from 'react';
import { connect } from 'react-redux';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ImageUpload from '../Extra/ImageUpload';
import Loading from '../Extra/Loading';
import { addCategoryAction } from '../../redux/actions';
import { auth, db } from '../../firebase';
import '../../App.css'
import { MdPictureAsPdf } from "react-icons/md"; 

class Profile extends React.Component {
	state = {
		url: '',
		urlPortada: '',
		description: '',
		whatsapp: '',
		instagram: '',
		facebook: '',
		shopName: '',
		loading: true,
		shopColor: '',
	};

	async componentDidMount() {
		auth.onAuthStateChanged(async (user) => {
			if (user) {
				let name;
				const data = await db.collection('users').doc(user.uid).get();

				const categories = [];
				data.data().categories.forEach((category) => {
					categories.push(category);
				});
				
				await db
				.collection('usersrelations')
				.where('useruid', '==', user.uid)
				.get()
				.then(function(querySnapshot) {
					querySnapshot.forEach(function(doc) {
						name = doc.data().name;
					});
				});

				this.setState({
					shopName: data.data().shopName || '',
					shopColor: data.data().shopColor || '',
					whatsapp: data.data().whatsapp || '',
					instagram: data.data().instagram || '',
					facebook: data.data().facebook || '',
					url: data.data().url || '',
					urlPortada: data.data().urlPortada || '',
					description: data.data().description || '',
					loading: false,
					name: name,
				});		
			} else {
				console.log('jajaj');
			}
		});
	}

	handleUrl = (urlChild) => {
		this.setState({ url: urlChild });
	};

	handleUrl2 = (urlChild) => {
		this.setState({ urlPortada: urlChild });
	};

	submitForm = async (e) => {
		let { url, shopName, shopColor, urlPortada, whatsapp, instagram, facebook, description} = this.state;
		var user_ = auth.currentUser;

		if (user_) {
			await db.collection('users').doc(user_.uid).set(
				{
					whatsapp,
					instagram,
					facebook,
					url,
					urlPortada,
					description,
					shopName,
					shopColor,
				},
				{ merge: true }
			);
			this.props.history.push('/dashboard');
		} else {
			console.log('ERROR');
		}
	};

	changeState = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	render() {
		const { url, shopName, shopColor, urlPortada, whatsapp, instagram, facebook, description } = this.state;
		if(this.state.loading){
      return(
        <Loading/>
      )
		}
		return (
			<div style={{backgroundColor: 'white', padding: '1em', borderRadius: '8px', }}>
				<div className="row justify-content-center">
					<form className="col-md-6">
						
            <div className="form-group">
							<label>Nombre </label>
							<input
								type="text"
								name="shopName"
								className="form-control"
								defaultValue={shopName}
								maxLength={28}
								onChange={this.changeState}
							/>
						</div>

						<div className="form-group">
							<label>Instagram (rellene con nombre de su tienda, sin @)</label>
							<input
								type="text"
								name="instagram"
								className="form-control"
								defaultValue={instagram}
								maxLength={21}
								onChange={this.changeState}
							/>
						</div>

						<div className="form-group">
							<label>Whatsapp</label>
							<PhoneInput
								value={whatsapp}
								country={'cl'}
								onChange={whatsapp => this.setState({whatsapp})}
								enableSearch={true}
								preferredCountries={['cl']}
							/>
						</div>

						<div className="form-group">
							<label>Facebook</label>
							<input
								type="text"
								name="facebook"
								className="form-control"
								defaultValue={facebook}
								maxLength={21}
								onChange={this.changeState}
							/>
						</div>

						<div className="form-group">
							<label> Descripción de tu Empresa | Pyme | Negocio </label>
							<textarea
								type="text"
								name="description"
								className="form-control"
								defaultValue={description}
								onChange={this.changeState}
							/>
						</div>

						<div className="form-group">
							<label className="mr-2"> Elija Color para su tienda </label>
							<input
								type="color"
								name="shopColor"
								defaultValue={shopColor}
								onChange={this.changeState}
							/>
						</div>

						<div className="form-group">
							<label className="mr-2"> Acá podrás ver tu informe mensual correspondiente al mes anterior </label>
						</div>
						<a className="button" href={'https://landing.simpleqr.cl/informes/' + this.state.name + '.pdf'} rel="noopener noreferrer" target="_blank">
							<MdPictureAsPdf/> {'Informe Mensual'}{' '}
						</a>

					</form>

					<div className="col-md-6">
						<div className="row justify-content-center mt-3" style={{ fontSize: 20, fontWeight: 'bold' }}>
							Foto Logo
						</div>
						<div className="row justify-content-center mt-3">
							<ImageUpload id={'perfil'} url={url} onSelectUrl={this.handleUrl} />
						</div>
						<div className="row justify-content-center mt-3" style={{ fontSize: 20, fontWeight: 'bold' }}>
							Foto Portada (Quiénes Somos)
						</div>
						<div className="row justify-content-center mt-3">
							<ImageUpload id={'portada'} url={urlPortada} onSelectUrl={this.handleUrl2} />
						</div>
					</div>
  
				</div>

				<button
					type="submit"
					className="button"
					onClick={(e) => this.submitForm(e)}
				>
					Guardar Cambios
				</button>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user
});

const mapDispatchToProps = (dispatch) => ({
	addCategory(category) {
		dispatch(addCategoryAction(category));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
