import React, { Fragment } from 'react';
import NewCategoryProductForm from '../NewCategoryProductForm';

class newProduct extends React.Component {
	render() {
		const data = {
			price: '',
			price2: '',
			tag: '',
			commentary: '',
			name: '',
			stock: '',
			images: [],
			subCategories: [],
			toggleEspecification: false
		};

		return (
			<div style={{ borderRadius: '8px', paddingRight: '15px', paddingLeft: '15px', backgroundColor: 'white', paddingBottom: '30px'}}>
				<NewCategoryProductForm
					history={this.props.history}
					data={data}
					newCategory={false}
					newProduct={true}
				/>
			</div>
		);
	}
}

export default newProduct;
