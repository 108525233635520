import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import ImageUpload from './ImageUpload';
// import { editProductImagesQuery } from '../../db';
import { auth, db } from '../../firebase';

export const palabraRandom = (length) => {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

class Profile extends React.Component {
	state = {
		images: [],
		url: '',
		shopuid: '',
		productID: ''
	};

	async componentDidMount() {
		console.log(this.props);
		const productID = this.props.match.params.id;
		this.setState({
			productID,
			images: this.props.location.state.images
		});
	}

	handleUrl = (urlChild, id) => {
		const images = this.state.images.map((image) => {
			if (image.id === id) {
				image.url = urlChild;
			}
			return image;
		});

		this.setState({ images });
	};

	submitForm = async (e) => {
		const { images } = this.state;
		var user_ = auth.currentUser;

		await db
			.collection('users')
			.doc(user_.uid)
			.collection(this.props.location.state.category)
			.doc(this.props.match.params.id)
			.set(
				{
					images
				},
				{ merge: true }
			);

		this.props.history.push('/dashboard');
	};

	newImage = () => {
		this.setState({
			images: this.state.images.concat([ { url: '', id: palabraRandom(15) } ])
		});
	};

	deleteMember = (i) => () => {
		this.setState({
			images: this.state.images.filter((image) => image.id !== i)
		});
	};

	render() {
		return (
			<Fragment>
				<div className="row justify-content-center">
					<h3> Imágenes </h3>
				</div>

				<div className="row">
					{this.state.images.map((image) => {
						return (
							<div key={image.id} className="col-md-4 mt-5">
								<div className="row">
									<ImageUpload
										url={image.url}
										productID={this.state.productID}
										id={image.id}
										onSelectUrl={this.handleUrl}
									/>
									{/* <ImageUpload id={member.id} url={member.url} onSelectUrl={this.handleUrl} /> */}
								</div>
								<div className="row">
									<button
										onClick={this.deleteMember(image.id)}
										type="button"
										className="btn btn-danger"
									>
										{' '}
										Eliminar{' '}
									</button>
								</div>
							</div>
						);
					})}
				</div>
				<div className="form-group d-flex justify-content-center col-md-12">
					<button
						onClick={this.newImage}
						type="button"
						className="button mt-4"
					>
						Agregar Nueva Foto
					</button>
				</div>

				<button
					type="button"
					className="button float-right"
					onClick={(e) => this.submitForm(e)}
				>
					Guardar Cambios
				</button>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user
});

export default connect(mapStateToProps)(Profile);
