const tarapaca = [
	{ value: 'altoHospicio', label: 'Alto Hospicio', price: 2000},
	{ value: 'camina', label: 'Camiña' },
	{ value: 'colchane', label: 'Colchane' },
	{ value: 'huara', label: 'Huara' },
	{ value: 'iquique', label: 'Iquique' },
	{ value: 'pica', label: 'Pica' },
	{ value: 'pozoAlmonte', label: 'Pozo Almonte' }
];

const antofagasta = [
	{ value: 'antofagasta', label: 'Antofagasta' },
	{ value: 'calama', label: 'Calama' },
	{ value: 'mariaElena', label: 'Maria Elena' },
	{ value: 'sanPedro', label: 'San Pedro' },
	{ value: 'sierraGorda', label: 'Sierra Gorda' },
	{ value: 'taltal', label: 'Taltal' },
	{ value: 'tocopilla', label: 'Tocopilla' }
];

const atacama = [
	{ value: 'altoDelCarmen', label: 'Alto del Carmen' },
	{ value: 'caldera', label: 'Caldera' },
	{ value: 'chanaral', label: 'Chañaral' },
	{ value: 'copiapo', label: 'Copiapó' },
	{ value: 'diegoDeAlmagro', label: 'Diego de Almagro' },
	{ value: 'freirina', label: 'Freirina' },
	{ value: 'tierraAmarilla', label: 'Tierra Amarilla' },
	{ value: 'vallenar', label: 'Vallenar' }
];

const coquimbo = [
	{ value: 'andacollo', label: 'Andacollo' },
	{ value: 'canela', label: 'Canela' },
	{ value: 'combarbala', label: 'Combarbala' },
	{ value: 'coquimbo', label: 'Coquimbo' },
	{ value: 'illapel', label: 'Illapel' },
	{ value: 'laHiguera', label: 'La Higuera' },
	{ value: 'laSerena', label: 'La Serena' },
	{ value: 'losVilos', label: 'Los Vilos' },
	{ value: 'montePatria', label: 'Monte Patria' },
	{ value: 'ovalle', label: 'Ovalle' },
	{ value: 'paihuano', label: 'Paihuano' },
	{ value: 'punitaqui', label: 'Punitaqui' },
	{ value: 'rioHurtado', label: 'Rio Hurtado' },
	{ value: 'salamanca', label: 'Salamanca' },
	{ value: 'vicuña', label: 'Vicuña' }
];

const valparaiso = [
	{ value: 'algarrobo', label: 'Algarrobo' },
	{ value: 'cabildo', label: 'Cabildo' },
	{ value: 'calera', label: 'Calera' },
	{ value: 'calleLarga', label: 'Calle Larga' },
	{ value: 'cartagena', label: 'Cartagena' },
	{ value: 'casablanca', label: 'Casa Blanca' },
	{ value: 'catemu', label: 'Catemu' },
	{ value: 'concon', label: 'Concón' },
	{ value: 'elQuisco', label: 'El Quisco' },
	{ value: 'elTabo', label: 'El Tabo' },
	{ value: 'hijuelas', label: 'Hijuelas' },
	{ value: 'islaDePascua', label: 'Isla de Pascua' },
	{ value: 'juanFernandez', label: 'Juán Fernández' },
	{ value: 'laCruz', label: 'La Cruz' },
	{ value: 'laLigua', label: 'La Ligua' },
	{ value: 'limache', label: 'Limache' },
	{ value: 'llayLlay', label: 'Llay Llay' },
	{ value: 'losAndes', label: 'Los Andes' },
	{ value: 'nogales', label: 'Nogales' },
	{ value: 'olmue', label: 'Olmue' },
	{ value: 'panquehue', label: 'Panquehue' },
	{ value: 'papudo', label: 'Papudo' },
	{ value: 'petorca', label: 'Petorca' },
	{ value: 'puchuncavi', label: 'Puchuncaví' },
	{ value: 'putaendo', label: 'Putaendo' },
	{ value: 'quillota', label: 'Quillota' },
	{ value: 'quilpue', label: 'Quilpué' },
	{ value: 'quintero', label: 'Quintero' },
	{ value: 'rinconada', label: 'Rinconada' },
	{ value: 'sanAntonio', label: 'San Antonio' },
	{ value: 'sanEsteban', label: 'San Esteban' },
	{ value: 'sanFelipe', label: 'San Felipe' },
	{ value: 'santaMaria', label: 'Santa María' },
	{ value: 'santoDomingo', label: 'Santo Domingo' },
	{ value: 'valparaiso', label: 'Valparaíso' },
	{ value: 'viñaDelMar', label: 'Viña del Mar' },
	{ value: 'villaAlemana', label: 'Villa Alemana' },
	{ value: 'zapallar', label: 'Zapallar' }
];
const regionMetropolitana = [
	{ value: 'alhue', label: 'Alhué' },
	{ value: 'buin', label: 'Buin' },
	{ value: 'caleraDeTango', label: 'Calera de Tango' },
	{ value: 'cerrillos', label: 'Cerrillos' },
	{ value: 'cerroNavia', label: 'Cerro Navia' },
	{ value: 'chicureo', label: 'Chicureo' },
	{ value: 'colina', label: 'Colina' },
	{ value: 'conchali', label: 'Conchalí' },
	{ value: 'curacavi', label: 'Curacaví' },
	{ value: 'elBosque', label: 'El Bosque' },
	{ value: 'elMonte', label: 'El Monte' },
	{ value: 'estacionCentral', label: 'Estación Central' },
	{ value: 'huechuraba', label: 'Huechuraba' },
	{ value: 'independencia', label: 'Independencia' },
	{ value: 'islaDeMaipo', label: 'Isla de Maipo' },
	{ value: 'laCisterna', label: 'La Cisterna' },
	{ value: 'laFlorida', label: 'La Florida' },
	{ value: 'laGranja', label: 'La Granja' },
	{ value: 'laPintana', label: 'La Pintana' },
	{ value: 'laReina', label: 'La Reina' },
	{ value: 'lampa', label: 'Lampa' },
	{ value: 'lasCondes', label: 'Las Condes' },
	{ value: 'loBarnechea', label: 'Lo Barnechea' },
	{ value: 'loEspejo', label: 'Lo Espejo' },
	{ value: 'loPrado', label: 'Lo Prado' },
	{ value: 'macul', label: 'Macul' },
	{ value: 'maipu', label: 'Maipú' },
	{ value: 'mariaPinto', label: 'María Pinto' },
	{ value: 'melipilla', label: 'Melipilla' },
	{ value: 'nunoa', label: 'Ñuñoa' },
	{ value: 'padreHurtado', label: 'Padre Hurtado' },
	{ value: 'paine', label: 'Paine' },
	{ value: 'penaflor', label: 'Peñaflor' },
	{ value: 'penalolen', label: 'Peñalolen' },
	{ value: 'pedroAguirreCerda', label: 'Pedro Aguirre Cerda' },
	{ value: 'pirque', label: 'Pirque' },
	{ value: 'providencia', label: 'Providencia' },
	{ value: 'pudahuel', label: 'Pudahuel' },
	{ value: 'puenteAlto', label: 'Puente Alto' },
	{ value: 'quilicura', label: 'Quilicura' },
	{ value: 'quintaNormal', label: 'Quinta Normal' },
	{ value: 'recoleta', label: 'Recoleta' },
	{ value: 'renca', label: 'Renca' },
	{ value: 'sanBernardo', label: 'San Bernardo' },
	{ value: 'sanJoaquin', label: 'San Joaquín' },
	{ value: 'sanJoseDeMaipo', label: 'San José de Maipo' },
	{ value: 'sanMiguel', label: 'San Miguel' },
	{ value: 'sanPedro', label: 'San Pedro' },
	{ value: 'sanRamon', label: 'San Ramón' },
	{ value: 'santiago', label: 'Santiago' },
	{ value: 'talagante', label: 'Talagante' },
	{ value: 'tiltil', label: 'Til Til' },
	{ value: 'vitacura', label: 'Vitacura' }
];
const ohiggins = [
	{ value: 'chepica', label: 'Chépica' },
	{ value: 'chimbarongo', label: 'Chimbarongo' },
	{ value: 'codegua', label: 'Codegua' },
	{ value: 'coinco', label: 'Coinco' },
	{ value: 'coltauco', label: 'Coltauco' },
	{ value: 'doñihue', label: 'Doñihue' },
	{ value: 'graneros', label: 'Graneros' },
	{ value: 'laEstrella', label: 'Las Cabras' },
	{ value: 'litueche', label: 'Litueche' },
	{ value: 'lolol', label: 'Lolol' },
	{ value: 'machali', label: 'Machalí' },
	{ value: 'malloa', label: 'Malloa' },
	{ value: 'marchigue', label: 'Marchigue' },
	{ value: 'mostazal', label: 'Mostazal' },
	{ value: 'nancagua', label: 'Nancagua' },
	{ value: 'navidad', label: 'Navidad' },
	{ value: 'olivar', label: 'Olivar' },
	{ value: 'palmilla', label: 'Palmilla' },
	{ value: 'paredones', label: 'Paredones' },
	{ value: 'peralillo', label: 'Peralillo' },
	{ value: 'peumo', label: 'Peumo' },
	{ value: 'pichidegua', label: 'Pichidegua' },
	{ value: 'pichilemu', label: 'Pichilemu' },
	{ value: 'placilla', label: 'Placilla' },
	{ value: 'pumanque', label: 'Pumanque' },
	{ value: 'quintaDeTilcoco', label: 'Quinta de Tilcoco' },
	{ value: 'rancagua', label: 'Rancagua' },
	{ value: 'rengo', label: 'Rengo' },
	{ value: 'requinoa', label: 'Requinoa' },
	{ value: 'sanFernando', label: 'San Fernando' },
	{ value: 'sanVicente', label: 'San Vicente' },
	{ value: 'santaCruz', label: 'Santa Cruz' }
];
const maule = [
	{ value: 'cauquenes', label: 'Cauquenes' },
	{ value: 'chanco', label: 'Chanco' },
	{ value: 'colbun', label: 'Colbún' },
	{ value: 'constitucion', label: 'Constitución' },
	{ value: 'curepto', label: 'Curepto' },
	{ value: 'empedrado', label: 'Empedrado' },
	{ value: 'hualahue', label: 'Hualahué' },
	{ value: 'licanten', label: 'Licantén' },
	{ value: 'linares', label: 'Linares' },
	{ value: 'longavi', label: 'Longaví' },
	{ value: 'maule', label: 'Maule' },
	{ value: 'molina', label: 'Molina' },
	{ value: 'parral', label: 'Parral' },
	{ value: 'pelarco', label: 'Pelarco' },
	{ value: 'pelluhue', label: 'Pelluhue' },
	{ value: 'pencahue', label: 'Pencahue' },
	{ value: 'rioClaro', label: 'Río Claro' },
	{ value: 'rauco', label: 'Rauco' },
	{ value: 'retiro', label: 'Retiro' },
	{ value: 'romeral', label: 'Romeral' },
	{ value: 'sagradaFamilia', label: 'Sagrada Familia' },
	{ value: 'sanClemente', label: 'San Clemente' },
	{ value: 'sanJavier', label: 'San Javier' },
	{ value: 'sanRafael', label: 'San Rafael' },
	{ value: 'talca', label: 'Talca' },
	{ value: 'teno', label: 'Teno' },
	{ value: 'vichuquen', label: 'Vichuquén' },
	{ value: 'villaAlegre', label: 'Villa Alegre' },
	{ value: 'yerbasBuenas', label: 'Yerbas Buenas' }
];
const biobio = [
	{ value: 'niquen', label: 'Ñiquén' },
	{ value: 'altoBiobio', label: 'Alto Biobío' },
	{ value: 'antuco', label: 'Antuco' },
	{ value: 'arauco', label: 'Bulnes' },
	{ value: 'cabrero', label: 'Cabrero' },
	{ value: 'chiguayante', label: 'Chiguayante' },
	{ value: 'chillan', label: 'Chillán' },
	{ value: 'chillanViejo', label: 'Chillán Viejo' },
	{ value: 'cobquecura', label: 'Cobquecura' },
	{ value: 'coelemu', label: 'Coelemu' },
	{ value: 'coihueco', label: 'Coihueco' },
	{ value: 'concepcion', label: 'Concepción' },
	{ value: 'contulmo', label: 'Contulmo' },
	{ value: 'coronel', label: 'Coronel' },
	{ value: 'curanilahue', label: 'Curanilahue' },
	{ value: 'elCarmen', label: 'El Carmen' },
	{ value: 'florida', label: 'Florida' },
	{ value: 'hualpen', label: 'Hualpén' },
	{ value: 'hualqui', label: 'Hualqui' },
	{ value: 'laja', label: 'Laja' },
	{ value: 'lebu', label: 'Lebu' },
	{ value: 'losAlamos', label: 'Los Álamos' },
	{ value: 'losAngeles', label: 'Los Ángeles' },
	{ value: 'lota', label: 'Lota' },
	{ value: 'mulchen', label: 'Mulchén' },
	{ value: 'nacimiento', label: 'Nacimiento' },
	{ value: 'negrete', label: 'Negrete' },
	{ value: 'ninhue', label: 'Ninhue' },
	{ value: 'pemuco', label: 'Pemuco' },
	{ value: 'penco', label: 'Penco' },
	{ value: 'pinto', label: 'Pinto' },
	{ value: 'portezuelo', label: 'Portezuelo' },
	{ value: 'quilaco', label: 'Quilaco' },
	{ value: 'quillón', label: 'Quillón' },
	{ value: 'quilleco', label: 'Quilleco' },
	{ value: 'quirihue', label: 'Quirihue' },
	{ value: 'ranquil', label: 'Ranquil' },
	{ value: 'portezuelo', label: 'Portezuelo' },
	{ value: 'sanCarlos', label: 'San Carlos' },
	{ value: 'sanFabián', label: 'San Fabián' },
	{ value: 'sanIgnacio', label: 'San Ignacio' },
	{ value: 'sanNicolás', label: 'San Nicolás' },
	{ value: 'sanPedroDelaPaz', label: 'San Pedro de la Paz' },
	{ value: 'sanRosendo', label: 'San Rosendo' },
	{ value: 'santaBarbara', label: 'Santa Bárbara' },
	{ value: 'santaJuana', label: 'Santa Juana' },
	{ value: 'talcahuano', label: 'Talcahuano' },
	{ value: 'tirua', label: 'Tirua' },
	{ value: 'tome', label: 'Tomé' },
	{ value: 'trehuaco', label: 'Trehuaco' },
	{ value: 'tucapel', label: 'Tucapel' },
	{ value: 'portezuelo', label: 'Portezuelo' },
	{ value: 'yumbel', label: 'Yumbel' },
	{ value: 'yungay', label: 'Yungay' }
];
const araucania = [
	{ value: 'angol', label: 'Angol' },
	{ value: 'carahue', label: 'Carahue' },
	{ value: 'cholchol', label: 'Cholchol' },
	{ value: 'collipulli', label: 'Collipulli' },
	{ value: 'cunco', label: 'Cunco' },
	{ value: 'curacautín', label: 'Curacautín' },
	{ value: 'curarrehue', label: 'Curarrehue' },
	{ value: 'ercilla', label: 'Ercilla' },
	{ value: 'freire', label: 'Freire' },
	{ value: 'galvarino', label: 'Galvarino' },
	{ value: 'gorbea', label: 'Gorbea' },
	{ value: 'lautaro', label: 'Lautaro' },
	{ value: 'loncoche', label: 'Loncoche' },
	{ value: 'lonquimay', label: 'Lonquimay' },
	{ value: 'losSauces', label: 'Los Sauces' },
	{ value: 'lumaco', label: 'Lumaco' },
	{ value: 'melipeuco', label: 'Melipeuco' },
	{ value: 'nuevaImperial', label: 'Nueva Imperial' },
	{ value: 'padreLasCasas', label: 'Padre las Casas' },
	{ value: 'perquenco', label: 'Perquenco' },
	{ value: 'pitrufquen', label: 'Pitrufquén' },
	{ value: 'pucon', label: 'Pucón' },
	{ value: 'puren', label: 'Purén' },
	{ value: 'renaico', label: 'Renaico' },
	{ value: 'saavedra', label: 'Saavedra' },
	{ value: 'temuco', label: 'Temuco' },
	{ value: 'teodoroSchmidt', label: 'Teodoro Schmidt' },
	{ value: 'tolten', label: 'Toltén' },
	{ value: 'traiguen', label: 'Traiguén' },
	{ value: 'victoria', label: 'Victoria' },
	{ value: 'vilcun', label: 'Vilcún' },
	{ value: 'villarrica', label: 'Villarrica' }
];
const losLagos = [
	{ value: 'ancud', label: 'Ancud' },
	{ value: 'calbuco', label: 'Calbuco' },
	{ value: 'castro', label: 'Castro' },
	{ value: 'chaiten', label: 'Chaitén' },
	{ value: 'chonchi', label: 'Chonchi' },
	{ value: 'cochamo', label: 'Cochamó' },
	{ value: 'curacoDeVelez', label: 'Curaco de Vélez' },
	{ value: 'dalcahue', label: 'Dalcahue' },
	{ value: 'fresia', label: 'Fresia' },
	{ value: 'frutillar', label: 'Frutillar' },
	{ value: 'futaleufu', label: 'Futaleufú' },
	{ value: 'hualaihue', label: 'Hualaihue' },
	{ value: 'llanquihue', label: 'Llanquihue' },
	{ value: 'losMermos', label: 'Los Mermos' },
	{ value: 'maullin', label: 'Maullín' },
	{ value: 'osorno', label: 'Osorno' },
	{ value: 'palena', label: 'Palena' },
	{ value: 'puertoMontt', label: 'Puerto Montt' },
	{ value: 'puertoOctay', label: 'Puerto Octay' },
	{ value: 'puertoVaras', label: 'Puerto Varas' },
	{ value: 'puqueldon', label: 'Puqueldón' },
	{ value: 'puyehue', label: 'Puyehue' },
	{ value: 'queilen', label: 'Queilén' },
	{ value: 'quellon', label: 'Quellón' },
	{ value: 'quemchi', label: 'Quemchi' },
	{ value: 'quinchao', label: 'Quinchao' },
	{ value: 'rioNegro', label: 'Río Negro' },
	{ value: 'sanJuanDeLaCosta', label: 'San Juan de la Costa' },
	{ value: 'sanPablo', label: 'San Pablo' }
];
const aysen = [
	{ value: 'aysen', label: 'Aysén' },
	{ value: 'chileChico', label: 'Chile Chico' },
	{ value: 'cisnes', label: 'Cisnes' },
	{ value: 'cochrane', label: 'Cochrane' },
	{ value: 'coyhaique', label: 'Coyhaique' },
	{ value: 'guaitecas', label: 'Guaitecas' },
	{ value: 'lagoVerde', label: 'Lago Verde' },
	{ value: 'rioIbanez', label: 'Río Ibáñez' },
	{ value: 'tortel', label: 'Tortel' }
];
const magallanes = [
	{ value: 'antartica', label: 'Antártica' },
	{ value: 'caboDeHornos', label: 'Cabo de Hornos' },
	{ value: 'lagunaBlanca', label: 'Laguna Blanca' },
	{ value: 'natales', label: 'Natales' },
	{ value: 'porvenir', label: 'Porvenir' },
	{ value: 'primavera', label: 'Primavera' },
	{ value: 'puntaArenas', label: 'Punta Arenas' },
	{ value: 'rioVerde', label: 'Río Verde' },
	{ value: 'sanGregorio', label: 'San Gregorio' },
	{ value: 'timaukel', label: 'Timaukel' },
	{ value: 'torresDelPaine', label: 'Torres del Paine' }
];
const losRios = [
	{ value: 'corral', label: 'Correl' },
	{ value: 'futrono', label: 'Futrono' },
	{ value: 'laUnion', label: 'La Unión' },
	{ value: 'lagoRanco', label: 'Lago Ranco' },
	{ value: 'lanco', label: 'Lanco' },
	{ value: 'losLagos', label: 'Los Lagos' },
	{ value: 'mafil', label: 'Mafil' },
	{ value: 'mariquina', label: 'Mariquina' },
	{ value: 'paillaco', label: 'Paillaco' },
	{ value: 'panguipulli', label: 'Panguipulli' },
	{ value: 'rioBueno', label: 'Río Bueno' },
	{ value: 'valdivia', label: 'Valdivia' }
];
const aricaParinacota = [
	{ value: 'arica', label: 'Arica' },
	{ value: 'camarones', label: 'Camarones' },
	{ value: 'generalLagos', label: 'General Lagos' },
	{ value: 'putre', label: 'Putre' }
];

export const mapRegions = {
	regionMetropolitana: { name: 'Región Metropolitana', options: regionMetropolitana },
	tarapaca: { name: 'I Tarapacá', options: tarapaca },
	antofagasta: { name: 'II Antofagasta', options: antofagasta },
	atacama: { name: 'III Atacama', options: atacama },
	coquimbo: { name: 'IV Coquimbo', options: coquimbo },
	valparaiso: { name: 'V Valparaíso', options: valparaiso },
	ohiggins: { name: "VI Bernardo O'Higgins", options: ohiggins },
	maule: { name: 'VII Maule', options: maule },
	biobio: { name: 'VIII Biobío', options: biobio },
	araucania: { name: 'IX Araucanío', options: araucania },
	losLagos: { name: 'X Los Lagos', options: losLagos },
	aysen: { name: 'XI Aysen', options: aysen },
	magallanes: { name: 'XII  Magallanes', options: magallanes },
	losRios: { name: 'XIV Los Ríos', options: losRios },
	aricaParinacota: { name: 'XV Arica y Parinacota', options: aricaParinacota }
};

export const mapRegionsList = [
	'regionMetropolitana',
	'tarapaca',
	'antofagasta',
	'atacama',
	'coquimbo',
	'valparaiso',
	'ohiggins',
	'maule',
	'biobio',
	'araucania',
	'losLagos',
	'aysen',
	'magallanes',
	'losRios',
	'aricaParinacota'	
];

export const deliveryMap = {
	'regionMetropolitana': regionMetropolitana,
	'tarapaca': tarapaca,
	'antofagasta': antofagasta,
	'atacama': atacama,
	'coquimbo': coquimbo,
	'valparaiso': valparaiso,
	'ohiggins': ohiggins,
	'maule': maule,
	'biobio': biobio,
	'araucania': araucania,
	'losLagos': losLagos,
	'aysen': aysen,
	'magallanes': magallanes,
	'losRios': losRios,
	'aricaParinacota': aricaParinacota,
}

export const deliveryMap_ = {
	'regionMetropolitana': 'Región Metropolitana',
	'tarapaca': 'Tarapacá',
	'antofagasta': 'Antofagasta',
	'atacama': 'Atacama',
	'coquimbo': 'Coquimbo',
	'valparaiso': 'Valparaíso',
	'ohiggins': 'O`Higgins',
	'maule': 'Maule',
	'biobio': 'Bío Bío',
	'araucania': 'Araucanía',
	'losLagos': 'Los Lagos',
	'aysen': 'Aysen',
	'magallanes': 'Magallanes',
	'losRios': 'Los Rios',
	'aricaParinacota': 'Arica Parinacota',
}
