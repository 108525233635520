import React from 'react';
import Spinner from 'react-bootstrap/Spinner'

const Loading = () => {
   
    return (
        <div className='d-flex justify-content-center align-items-center' style={{height: '80vh'}}>
             <Spinner className='mr-3' animation="border" role="status" style={{color: 'rgb(244, 156, 52)'}}>
                <span className="sr-only">Cargando...</span>
            </Spinner>
            Cargando...
        </div>
       
    )
};

export default Loading;