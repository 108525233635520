import React, { Fragment, Component } from 'react';
import Select from 'react-select';

class PaymentForm extends Component {
	
	displayCategory = () => {
		return (
			<Fragment>
				<div className="form-group">
					<label>Elige el nombre de la nueva categoria</label>
					<input
						type="text"
						name="categoryName"
						className="form-control"
						defaultValue={this.props.categoryName}
						onChange={this.props.changeState}
					/>
				</div>
			</Fragment>
		);
	};

	displayName = () => {
		return (
			<Fragment>
				<div className="form-group">
					{this.props.newCategory === true ? (
						<label>¡ Crea tu primer producto ! Elige el nombre</label>
					) : (
						<label>Nombre</label>
					)}
					<input
						type="text"
						name="name"
						className="form-control"
						defaultValue={this.props.name}
						maxLength={20}
						onChange={this.props.changeState}
					/>
				</div>
			</Fragment>
		);
	};

	displayPrice = () => {
		return (
			<Fragment>
				<div className="form-group">
					<label>Precio</label>
				
					<input
						onChange={(e) => {
							if (/^[1-9][0-9]*$/.test(e.target.value) ||
							e.target.value === ''){
								this.props.changeState(e)
							}	
						}}
						type="text"
						name="price"
						className="form-control"
						value={this.props.price}
					/>
				</div>
			</Fragment>
		);
	};

	displayPrice2 = () => {
		return (
			<Fragment>
				<div className="form-group">
					<label>Precio Oferta</label>
				
					<input
						onChange={(e) => {
							if (/^[1-9][0-9]*$/.test(e.target.value) ||
							e.target.value === ''){
								this.props.changeState(e)
							}	
						}}
						type="text"
						name="price2"
						className="form-control"
						value={this.props.price2}
					/>
				</div>
			</Fragment>
		);
	};

	displayCommentary = () => {
		return (
			<Fragment>
					<div className="form-group">
						<label>Detalle del producto </label>
						<textarea
							type="text"
							name="commentary"
							className="form-control"
							defaultValue={this.props.commentary}
							rows="5"
							onChange={this.props.changeState}
						/>
					</div>
			</Fragment>
		);
	};

	displayStock = () => {
		return (
			<Fragment>
				{this.props.toggleEspecification === false || this.props.toggleEspecification === '' ? 
					<div className="row align-items-center">
						<div className="col-3 col-md-1">
							<input
								className="form-check"
								type="checkbox"
								name="stock"
								defaultChecked={this.props.stock}
								onChange={this.props.changeState}
							/>
						</div>
						<div className="col-9 col-md-6 mt-2">
							<label style={{ fontSize: 15 }}>Sin Stock</label>
						</div>
					</div> : ''}
			</Fragment>
		);
	};

	displayTag = () => {
		const tags = [
			{ value: 'Oferta', label: 'Oferta' },
			{ value: 'Nuevo', label: 'Nuevo' },
		];

		const tagsMap = {
			'Oferta': { value: 'Oferta', label: 'Oferta'},
			'Nuevo': { value: 'Nuevo', label: 'Nuevo'},
		}

		return(
			<Fragment>
					<div className="form-group">
						<label>Tag del producto (OPCIONAL) </label>
						<Select
							className="basic-single"
							classNamePrefix="select"
							isDisabled={false}
							isLoading={false}
							isClearable={true}
							isRtl={false}
							isSearchable={false}
							name="tag"
							defaultValue={tagsMap[this.props.tag]}
							options={tags}
							onChange={(event) => this.props.changeTag(event)}
						/>
					</div>
			</Fragment>
		)
	}


	render() {
		console.log("jajaja", this.props.toggleEspecification === true)
		return (
			<Fragment>
				{this.props.newCategory === true ? this.displayCategory() : ''}
				{this.displayName()}
				{this.props.toggleEspecification !== true ? this.displayPrice() : ''}
				{this.props.toggleEspecification !== true ? this.displayPrice2() : ''}
				{this.displayCommentary()}
				{this.displayTag()}
				{this.displayStock()}
			</Fragment>
			)
    }
    
}

export default PaymentForm;
