import React, { Fragment } from 'react';
import { auth, db } from '../../firebase';
import Loading from '../Extra/Loading';

class Suggestion extends React.Component {
	state = {
		suggestions: [],
		ordersAux: [],
		shopuid: '',
		loading: true
	};

	componentDidMount = async () => {
		auth.onAuthStateChanged(async (user) => {
			if (user) {
				let suggestions = [];

				const data = await db
					.collection('users')
					.doc(user.uid)
					.collection('suggestions')
					.orderBy('created', 'desc')
					.get();
				// .orderBy('created', 'desc');
				data.docs.forEach((doc) => {
					suggestions.push({
						id: doc.id,
						data: doc.data()
					});
				});

				this.setState({
					suggestions,
					loading: false
				});
			} else {
				console.log('Unmounted');
			}
		});
	};

	timeConverter(UNIX_timestamp) {
		var a = new Date(UNIX_timestamp * 1000);
		var months = [ 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec' ];
		var month = months[a.getMonth()];
		var date = a.getDate();
		var hour = a.getHours();
		var min = a.getMinutes();
		var sec = a.getSeconds();
		var time = date + ' ' + month + ' ' + hour + ':' + min + ':' + sec;
		return time;
	}
	render() {
		const { suggestions } = this.state;
		if(this.state.loading){
      return(
        <Loading/>
      )
		}
		return (
			<Fragment>
				<ul className="list-group mt-3" style={{ width: '100%' }}>
					{suggestions.map((suggestion) => {
						let backgroundColor_ = 'bg-success';
						if ([ '1', '2', '3' ].includes(suggestion.data.grade)) {
							backgroundColor_ = 'bg-danger';
						} else if ([ '4', '5' ].includes(suggestion.data.grade)) {
							backgroundColor_ = 'bg-warning';
						} else {
							backgroundColor_ = 'bg-success';
						}

						return (
							<div key={suggestion.id} className={`list-group-item mb-2 ${backgroundColor_}`}>
								<div className="row justify-content-center">
									<div className="col-md-2">
										<div className="row" style={{ fontSize: 15, fontWeight: 'bold' }}>
											Creado
										</div>
										<div className="row">{this.timeConverter(suggestion.data.created)}</div>
									</div>
									<div className="col-md-7">
										<div className="row" style={{ fontSize: 15, fontWeight: 'bold' }}>
											Comentario
										</div>
										<div className="row">{suggestion.data.commentary}</div>
									</div>
									<div className="col-md-2">
										<div className="row" style={{ fontSize: 15, fontWeight: 'bold' }}>
											Nota
										</div>
										<div className="row">{suggestion.data.grade}</div>
									</div>
								</div>
							</div>
						);
					})}
				</ul>
			</Fragment>
		);
	}
}

export default Suggestion;
