import React from 'react';
import { auth, db } from '../firebase';
import { connect } from 'react-redux';
import { CgPlayListAdd } from "react-icons/cg";
import './../App.css'
import SubCategories from "./SubCategories"
import ProductInputForm from "./ProductInputForm"

class NewCatProductForm extends React.Component {
	constructor(props) {
		super(props);
		let {name, stock, price, price2, tag, commentary, images, subCategories, toggleEspecification} = this.props.data;

		let id;
		if (this.props.data.subCategories === undefined) {
			id = 0;
			subCategories = [];
		} else {
			id = this.props.data.subCategories.length + 2;
			
		}

		this.state = {
			name,
			categoryName: '',
			price,
			price2: price2 || price,
			commentary,
			stock: stock || false,
			images,
			subCategories,
			id,
			tag,
			toggleEspecification: toggleEspecification || '',
			newCategory: this.props.newCategory,
		};
	}

	changeState = (e) => {

		const { name, value } = e.target;
		const { stock } = this.state

		if(name === 'stock'){
			if(stock === false){
				this.setState({
					stock: true
				})
			} else{
				this.setState({
					stock: false
				})
			}
			
		} else{
			this.setState({
				[name]: value
			});
		}

		
	};

	changeTag = (event) => {
		if(event === null){
			this.setState({
				tag: ''
			})
		} else{
			this.setState({
				tag: event.value
			})
		}
	}

	submitForm = async (e) => {
		let { name, stock, categoryName, price, price2, tag, commentary, images, subCategories, toggleEspecification } = this.state;
		const { category, newCategory, newProduct } = this.props;

		if(tag === undefined){
			tag = ''
		}
		var user_ = auth.currentUser;

		if (user_) {
			if (newCategory === false && newProduct === false) {
				db
					.collection('users')
					.doc(user_.uid)
					.collection(category.name)
					.doc(this.props.id)
					.update({
						price,
						price2,
						commentary,
						stock,
						name,
						tag,
						subCategories,
						toggleEspecification
					})
					.then(this.props.history.push('/dashboard'));
			} else if (newCategory === false && newProduct === true) {

				db
					.collection('users')
					.doc(user_.uid)
					.collection(category.name)
					.doc()
					.set({
						name,
						price,
						price2,
						tag,
						commentary,
						stock,
						images,
						priority: 1,
						subCategories,
						toggleEspecification
					})
					.then(this.props.history.push('/dashboard'));
			} else {
				const categories = this.props.categories.categories;

				const categories_ = [ ...categories, { name: categoryName, image: '' } ];

				await db.collection('users').doc(user_.uid).update({
					categories: categories_
				});

				//set de new categories
				db
					.collection('users')
					.doc(user_.uid)
					.collection(categoryName)
					.doc()
					.set({
						name,
						price,
						price2,
						tag,
						commentary,
						stock,
						images,
						priority: 1,
						subCategories,
						toggleEspecification
					})
					.then(() => {
						this.props.history.push('/dashboard');
					})
					.catch((err) => {
						console.log(err.message);
					});
			}
		} else {
			console.log('ERROR');
		}
	};

	changeBox = () => {
		let { toggleEspecification } = this.state;
		this.setState({ toggleEspecification: !toggleEspecification });
	};

	newcategory = () => {
		this.setState({
			subCategories: this.state.subCategories.concat([ { name: '', stock: false, id: this.state.id, price: 1000, 	price2: '',
			price2: '', }]),
			id: this.state.id + 1
		});
	};

	deleteCategory = (id) => {
		this.setState({
			subCategories: this.state.subCategories.filter((subCategories) => id !== subCategories.id)
		});	
	};

	readCategoryInformation = (event, id, type) => {
		const newCategory = this.state.subCategories.map((category) => {
			if (id !== category.id) return category;

			if (type === 'name') {
				category.name = event.target.value;
			}

			if (type === 'price') {
				category.price = event.target.value;
			}

			if (type === 'price2') {
				category.price2 = event.target.value;
			}

			if (type === 'stock') {
				category.stock = !category.stock;
			}

			return category;
		}) 
		
		this.setState({
			subCategories: newCategory
		})
	
	}

	render() {
		const { name, images } = this.state;

		//aqui tenemos que arreglar el disabled
		let disabled;
		if(this.state.newCategory){
			disabled = !this.state.categoryName || !this.state.name || !this.state.price
		} else{
			if(this.state.subCategories.length === 0 && this.state.toggleEspecification !== true){
				disabled = !this.state.name || !this.state.price
			} else{
				disabled = !this.state.name || this.state.subCategories.length === 0
		}
		}
		console.log("jajajaja", this.state.subCategories)
		return (
			<div style={{borderRadius: '8px', paddingRight: '15px', paddingLeft: '15px'}}>
				<div className="row justify-content-center mt-5">
					<h1> {name} </h1>
				</div>
				<div className="row">
					<div className="col-12 col-md-6">
						<ProductInputForm
							newCategory={this.props.newCategory}
							categoryName={this.state.categoryName}
							newcategory={this.state.newcategory}
							name={this.state.name}
							price={this.state.price}
							price2={this.state.price2}
							commentary={this.state.commentary}
							toggleEspecification={this.state.toggleEspecification}
							stock={this.state.stock}
							handleClick={this.handleClick}
							changeState={this.changeState}
							changeTag={this.changeTag}
							tag={this.state.tag}	
						/>
					</div>
				</div>

				<div className="row mt-5 mb-5" style={{borderBottomStyle: 'solid', borderWidth: '1px', borderColor: 'lightGray'}}>

				</div>

				<SubCategories 
					subCategories={this.state.subCategories}
					id={this.state.id}
					toggleEspecification={this.state.toggleEspecification}
					changeBox={this.changeBox}
					newcategory={this.newcategory}
					readCategoryInformation={this.readCategoryInformation}
					deleteCategory={this.deleteCategory}
				/>


				{/*AÑADIR IMAGENES*/}. 
				
				<div className="col-md-2">
					{this.props.id !== undefined ? (
						<button
							onClick={() =>
								this.props.history.push({
									pathname: `/dashboard/products/${this.props.id}/images`,
									state: { images: images, category: this.props.category.name }
								})}
							type="submit"
							className="button mb-3"
						>
							<CgPlayListAdd/> Añadir Imágenes
						</button>
					) : (
						''
					)}
				</div>
					
				
				{/*BOTON*/}. 

				<button
					onClick={(e) => this.submitForm(e)}
					type="submit"
					className="btn btn-success float-right mb-5"
					style={{color: 'white', fontWeight: 'bold' }}
					disabled={disabled}
				>
					Guardar Cambios
				</button>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	category: state.category
});

export default connect(mapStateToProps)(NewCatProductForm);
