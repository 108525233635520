import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { db } from '../../firebase';

class Orders extends React.Component {
	state = {
		name: ''
	};

	componentDidMount = async () => {
		let name;
		const uid = this.props.user.user.uid;
		await db
			.collection('usersrelations')
			.where('useruid', '==', uid)
			.get()
			.then(function(querySnapshot) {
				querySnapshot.forEach(function(doc) {
					name = doc.data().name;
				});
			});

		this.setState({
			name
		});
	};

	render() {
		return (
			<Fragment>
				<div className="container mb-3">
					<h3>Link</h3>
					<a href={'https://simpleqr.cl/' + this.state.name} rel="noopener noreferrer" target="_blank">
						{'https://simpleqr.cl/' + this.state.name}{' '}
					</a>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user
});

export default connect(mapStateToProps, {})(Orders);
