import React, { Fragment } from 'react';
import { auth, db } from '../../firebase';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

class Destacados extends React.Component {
	state = {
		products: [],
		categories: [ 'Alimentos', 'Accesorios', 'Ropa', 'Infantil', 'Bebidas', 'Mascotas', 'Deporte', 'Decoración', 'Otros' ],
		filter: 'Alimentos'
	};

	async componentDidMount() {
		const productsInformation = await db.collection('photos').where('category', '==', 'Alimentos').get();
		const products = [];

		productsInformation.docs.forEach((doc) => {
			products.push({
				doc: doc,
				id: doc.id,
				data_: doc.data()
			});
		});
		this.setState({
			products
		});
	}

	addCategory = (product) => {
		const category_ = this.props.location.state.category;

		auth.onAuthStateChanged(async (user) => {
			if (user) {
				const data = await db.collection('users').doc(user.uid).get();
				const categories = [];
				data.data().categories.forEach((category) => {
					if (category.name === category_.name) {
						categories.push({ name: category.name, image: product.data_.url });
					} else {
						categories.push(category);
					}
				});

				await db.collection('users').doc(user.uid).set({ categories }, { merge: true });
				this.props.history.push('/dashboard');
			} else {
				console.log('jajaj');
			}
		});
	};

	changeCategory = async (category) => {
		const productsInformation = await db.collection('photos').where('category', '==', `${category}`).get();
		const products = [];

		productsInformation.docs.forEach((doc) => {
			products.push({
				doc: doc,
				id: doc.id,
				data_: doc.data()
			});
		});
		this.setState({
			products,
			filter: category
		});
	};

	renderDropdown = () => {
		return (
			<Fragment>
				<DropdownButton id="dropdown-basic-button" title="Categorías">
					{this.state.categories.map((category, index) => {
						return (
							<Dropdown.Item key={index} onClick={() => this.changeCategory(category)}>
								{category}
							</Dropdown.Item>
						);
					})}
				</DropdownButton>
			</Fragment>
		);
	};

	render() {
		const { products } = this.state;

		return (
			<Fragment>
				<div className="row justify-content-center align-items-stretch ml-3 mr-3">
					<div className="row justify-content-center align-items-center mt-4 mb-4">
						<div className="col-md-6">{this.renderDropdown()}</div>
						<div className="col-md-6 mt-4">
							<h2 style={{ marginTop: 0, marginBottom: 0 }}> {this.state.filter} </h2>
						</div>
					</div>

					<div className="row justify-content-center ">
						{products.map((product) => {
							return (
								<Fragment>
									<div className="col-md-3 col-12 " key={product.url}>
										<div className="row justify-contenter-center mt-4 ml-4 mr-4">
											<img
												src={product.data_.url}
												alt="..."
												style={{ height: '200px', width: '200px', borderRadius: '5%' }}
											/>
										</div>
										<div className="row justify-contenter-center mt-4 ml-4 mr-4">
											<button
												className="button"
												onClick={() => this.addCategory(product)}
											>
												{'Seleccionar'}
											</button>
										</div>
									</div>
								</Fragment>
							);
						})}
					</div>
				</div>
			</Fragment>
		);
	}
}

export default Destacados;
