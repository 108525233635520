import firebase from 'firebase';

const firebaseConfig = {
	apiKey: 'AIzaSyBTzHguR3bWAduNCaAQGIgqO_f7gADBQFc',
	authDomain: 'bequickr-b9626.firebaseapp.com',
	databaseURL: 'https://bequickr-b9626.firebaseio.com',
	projectId: 'bequickr-b9626',
	storageBucket: 'bequickr-b9626.appspot.com'
};

// DEVELPMENT
// const firebaseConfig = {
//     apiKey: "AIzaSyDqJySrD7zm8kgk16bk5xdSjPWmuMYtzx0",
//     authDomain: "simpleqr-dev.firebaseapp.com",
//     databaseURL: "https://simpleqr-dev.firebaseio.com",
//     projectId: "simpleqr-dev",
//     storageBucket: "simpleqr-dev.appspot.com",
//   };

// firebase.analytics();
export const app = firebase.initializeApp(firebaseConfig);
export const auth = app.auth();
export const db = app.firestore();
export const storage = app.storage();
export const function_ = app.functions();
