import React from 'react';
import { function_ } from '../../firebase';

class Support extends React.Component {
  state = {
      name: '',
      number: '',
      email: '',
      text: '',
      message: '',
      send: false
  };

  updateState = (e) => {
      const { name, value } = e.target;
      this.setState({
          [name]: value
      });
  };

  handleClick = () => {
      const callableReturnMessage = function_.httpsCallable('sendEmail');

      callableReturnMessage({
          email: this.state.email,
          name: this.state.name,
          text: this.state.text,
          number: this.state.number
      })
          .then((result) => {
              console.log(result);
          })
          .catch((error) => {
              console.log(`error: ${JSON.stringify(error)}`);
          });

      this.setState({
          message: 'TU MENSAJE FUE ENVIADO CORRECTAMENTE',
          send: true
      });
      setTimeout(
          () =>
              this.setState({
                  message: ''
              }),
          3000
      );
  };

  render() {
    const { text, email, name, number, send } = this.state;
    return (
      <div className="container">
          <div className="row justify-content-center">
            {this.state.message}
            <div className="col-md-6">
              <div className="row justify-content-center">
                  <h4> Soporte </h4>
              </div>
              <input
                  className="form form-control mb-2"
                  placeholder="Nombre..."
                  type="name"
                  name="name"
                  onChange={this.updateState}
              />

              <input
                  className="form form-control mb-2"
                  placeholder="Correo..."
                  type="text"
                  name="email"
                  onChange={this.updateState}
              />

              <input
                  className="form form-control mb-2"
                  placeholder="Número de Teléfono..."
                  type="number"
                  name="number"
                  onChange={this.updateState}
              />

              <input
                  className="form form-control mb-2"
                  cols="80"
                  name="text"
                  placeholder="Escribe un mensaje..."
                  rows="4"
                  type="textarea"
                  onChange={this.updateState}
              />
              <div className="row justify-content-center">
                  <button
                      className="btn btn-lg"
                      style={{ backgroundColor: 'black', fontWeight: 600, color: 'white' }}
                      onClick={(e) => this.handleClick(e)}
                      size="lg"
                      disabled={!name || !email || !text | !number || send === true}
                  >
                      Enviar
                  </button>
              </div>
            </div>
          </div>
          <div className="row mt-4">Puedes hablarnos directamente vía WhatsApp al +56 9 6631 0670</div>
      </div>
    );
  }
}

export default Support;
