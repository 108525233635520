export const addUserAction = (user) => {
	return {
		type: 'ADD_USER',
		user: user
	};
};

export const addCategoryAction = (category) => {
	return {
		type: 'ADD_CATEGORY',
		category: category
	};
};
