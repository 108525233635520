import React from 'react';
import { auth } from '../../firebase';
import { connect } from 'react-redux';
import { addUserAction } from '../../redux/actions';
import firebase from 'firebase';
import logo from '../../images/simple.png';

class Login extends React.Component {
	state = {
		email: '',
		password: '',
		err: ''
	};

	updateState = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	login = (event, email, password) => {
		event.preventDefault();

		auth
			.setPersistence(firebase.auth.Auth.Persistence.SESSION)
			.then(() => {
				auth
					.signInWithEmailAndPassword(email, password)
					.then((cred) => {
						this.props.addUser(cred);
						this.props.history.push('/dashboard');
					})
					.catch((err) => {
						this.setState({
							err: err
						});
					});
			})
			.catch((err) => {
				this.setState({
					err: err
				});
			});
	};

	render() {
		const { email, password, err } = this.state;
    const alert_ = err !== '' ? <p style={{ color: 'red' }}>Usuario y/o contraseña inválidos.</p> : '';
    
		return (
			<div className="container">
				<div className="row justify-content-center">
					<img src={logo} alt="Logo" style={{ height: '44vh', maxWidth: '100%' }} />
				</div>

				{alert_}

				<div className="col-md-4 offset-md-4 mb-2">
					Correo Electrónico 
				</div>

				<div className="col-md-4 offset-md-4 mb-2">
          <input
            type="email"
            name="email"
            className="form-control mb-4"
            placeholder="hola@example.com"
            styles={{ width: '50%' }}
            onChange={this.updateState}
          />
				</div>

				<div className="col-md-4 offset-md-4 mb-2">
					Contraseña
				</div>

				<div className="col-md-4 offset-md-4 mb-2">
          <input
            type="password"
            name="password"
            className="form-control mb-4"
            onChange={this.updateState}
          />
				</div>

				<div className="row justify-content-center">
					<button
						className="btn-lg"
						style={{ backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}
						onClick={(event) => this.login(event, email, password)}
					>
						Iniciar Sesión
					</button>
				</div>
        
				<div className="row justify-content-center">
					<button
						className="row justify-content-start mt-2"
						onClick={() => this.props.history.push('/password')}
						style={{
							cursor: 'pointer',
							color: 'red',
							backgroundColor: 'transparent',
							borderStyle: 'none'
						}}
					>
						¿No recuerdas tu contraseña?
					</button>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	initial_data: state.initial_data
});

const mapDispatchToProps = (dispatch) => ({
	addUser(user) {
		dispatch(addUserAction(user));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
