import React, { Fragment } from 'react';
import { auth, db } from '../../firebase';
import { connect } from 'react-redux';
import { addCategoryAction } from '../../redux/actions';
import Loading from '../Extra/Loading';
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { CgPlayListAdd } from "react-icons/cg";

class Categories extends React.Component {
	state = {
		categories: [],
		loading: true
	};

	async componentDidMount() {
		auth.onAuthStateChanged(async (user) => {
			if (user) {
				const data = await db.collection('users').doc(user.uid).get();
				const categories = [];
				data.data().categories.forEach((category) => {
					categories.push(category);
				});
				this.setState({
					categories,
					user,
					loading: false
				});
			} else {
				console.log('jajaj');
			}
		});
	}

	editProducts = (e, category) => {
		this.props.addCategory(category);
		this.props.history.push('dashboard/products');
	};

	deleteCategory = async (e, deleteCategory) => {
		const { categories } = this.state;
		var user = auth.currentUser;
		if (user) {
			const categories_ = categories.filter((category) => category.name !== deleteCategory.name);
			const data = await db.collection('users').doc(user.uid).collection(deleteCategory.name).get();
			data.docs.forEach(async (doc) => {
				await db.collection('users').doc(user.uid).collection(deleteCategory.name).doc(doc.id).delete();
			});

			await db.collection('users').doc(user.uid).update({
				categories: categories_
			});

			this.setState({
				categories: categories_
			});
		}
	};

	render() {
		const { categories } = this.state;
		const { history } = this.props;
		if(this.state.loading){
            return(
                <Loading/>
            )
		}
		
		return (
			<Fragment>
				<ul className="list-group">
					{categories.map((category, index) => {
						return (
							<li key={index} className="list-group-item mb-2 pt-3 pb-3" style={{borderRadius: '8px'}}>
								<div className="row justify-content-center align-items-center">
									<div
										className="col-md-2 col-2 d-flex justify-content-between align-items-center"
										style={{ fontSize: 20 }}
									>
										{index + 1}.
									</div>

									<div
										className="col-md-6 col-6 d-flex justify-content-between align-items-center"
										style={{ fontSize: 20 }}
									>
										{category.name}
									</div>
									

									<div style={{cursor:'pointer'}} onClick={(e) => this.editProducts(e, category)} className="col-md-2 col-2 d-flex justify-content-end align-items-center mt-2">
										<AiTwotoneEdit
											size={20}
										/>
										&nbsp; <span> Agregar / Editar </span>
									</div>
									<div className="col-md-2 col-2 d-flex justify-content-end mt-2">
										<AiFillDelete
											size={20}
											style={{cursor:'pointer'}}
											onClick={(e) => {
												if (window.confirm('¿Seguro desde eliminar la categoría?'))
													this.deleteCategory(e, category);
											}}
										/>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
				<div className="row justify-content-center">
					<button
						className="button mt-3 ml-3"
						onClick={() =>
							history.push({
								pathname: `/dashboard/categories/newcategory`,
								state: { categories }
							})}
					>
						<CgPlayListAdd/> Agregar Nueva Categoría
					</button>
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user
});

const mapDispatchToProps = (dispatch) => ({
	addCategory(category) {
		dispatch(addCategoryAction(category));
	}
});

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
