import React, { Fragment } from 'react';
import ReactPlayer from "react-player";

class Tutorial extends React.Component {

	render() {
		return (
			<Fragment>
				<div className="container mb-3">
					<h3>1.Creación de Perfil</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=fSIVBjDFNYY"
					/>
				</div>

				<div className="container mb-3">
					<h3>2.Crear y Agregar Productos</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=boZW9jHx60M"
					/>
				</div>

				<div className="container mb-3">
					<h3>3. Delivery</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=kylUKmwAg0w"
					/>
				</div>

				<div className="container mb-3">
					<h3>4. Otras funciones y tu página</h3>
					<ReactPlayer
						url="https://www.youtube.com/watch?v=_8Lbu2wNqSw "
					/>
				</div>
			</Fragment>
		);
	}
}

export default Tutorial;
