import React, { Fragment } from 'react';
import { CgPlayListAdd } from "react-icons/cg";
import './../App.css'

class NewCatProductForm extends React.Component {

	
	toggleEspecification = () => (
		<Fragment>
			<div className="row align-items-center">
				<input
					className="col-md-2 col-2 mr-3 mb-2 ml-5"
					type="checkbox"
					name="toggleEspecification"
					checked={this.props.toggleEspecification}
					onChange={this.props.changeBox}
				/>
				<div className="col-md-8 col-10" style={{ fontSize: 18 }}> (Debes seleccionar el casillero para activar este campo) </div>
			</div>
		</Fragment>
	);

	displayStockCategories = () => {

		return (
			<Fragment>
				<div className="row mt-3 mb-3 ml-3">
					<h4> Especificaciones del producto </h4> 
				</div>
				<div className="row mt-3 mb-3 ml-3">
					{this.toggleEspecification()}
				</div>

				{this.props.toggleEspecification === true ? (
					<div className="row">
						<div className="col-md-8">
							{this.props.subCategories.map((category) => {
								return (
									<div key={category.id} className="form-group">
										<div className="row align-items-center mt-4">
											<div className="col-md-12 col-12">
												<label> Nombre </label>
												<input
													onChange={(event) =>
														this.props.readCategoryInformation(event, category.id, 'name')}
													type="text"
													value={category.name}
													placeholder="Nombre"
													className="form-control"
												/>
											</div>
											
											<div className="col-md-3 col-12 mt-2">
												<label> Precio </label>
												<input
													onChange={(e) => {
														if (/^[1-9][0-9]*$/.test(e.target.value) ||
														e.target.value === ''){
															this.props.readCategoryInformation(e, category.id, 'price')
														}	
													}}
												
													type="text"
													value={category.price}
													placeholder="Precio"
													className="form-control"
												/>
											</div>

											<div className="col-md-3 col-12 mt-2">
												<label> Precio Oferta </label>
												<input
													onChange={(e) => {
														if (/^[1-9][0-9]*$/.test(e.target.value) ||
														e.target.value === ''){
															this.props.readCategoryInformation(e, category.id, 'price2')
														}	
													}}
												
													type="text"
													value={category.price2}
													placeholder="Precio Oferta"
													className="form-control"
												/>
											</div>

											<div className="col-3 col-md-1 mt-3 ml-2">
												<input
													className="form-check"
													type="checkbox"
													name="stock"
													defaultChecked={category.stock}
													onChange={(event) =>
														this.props.readCategoryInformation(event, category.id, 'stock')}
												/>
											</div>

											<div className="col-5 col-md-2 mt-2">
												<label style={{ fontSize: 15 }}>Sin Stock</label>
											</div>

											<div className="col-3 col-md-2 mt-2">
												<button
													onClick={() => this.props.deleteCategory(category.id)}
													type="button"
													className="btn btn-danger"
												>
													&times;
												</button>
											</div>

										</div>
									</div>
								);
							})}

							<div className="form-group d-flex justify-content-center">
								<button
									onClick={this.props.newcategory}
									type="button"
									className="btn btn-success mt-3"
								>
									<CgPlayListAdd/> Agregar Sub Categoría
								</button>
							</div>
						</div>

						<div className="col-md-4 col-12" style={{ borderStyle: 'solid', borderRadius: '20px' }}>
							<div className="row justify-content-center pt-3 pb-2">
								<span style={{ fontSize: 25, fontWeight: 'bold', color: 'gray' }}> IMPORTANTE </span>
							</div>
							<div className="row pr-3 pl-3 pb-2 mb-4" style={{ fontSize: 17 }}>
								{' '}
								Acá puedes añadir tamaño, salsas, formatos, entre otras subCategorías. Para crear un campo personalizado debes marcar la casilla “Especificaciones del Producto"{' '}
							</div>
						</div>
					</div>
				) : (
					<div className="col-md-4 offset-md-8" style={{ borderStyle: 'solid', borderRadius: '20px' }}>
						<div className="row justify-content-center pt-3 pb-2">
							<span style={{ fontSize: 25, fontWeight: 'bold', color: 'gray' }}> IMPORTANTE </span>
						</div>
						<div className="row pr-3 pl-3 pb-2" style={{ fontSize: 17 }}>
							{' '}
							Acá puedes añadir tamaño, salsas, formatos, entre otras subCategorías. Para crear un campo personalizado debes marcar la casilla “Especificaciones del Producto"{' '}
						</div>
					</div>
				)}
			</Fragment>
		);
	};

	render() {

		return (
			<div>
				<form>{this.displayStockCategories()}</form>
			</div>
		);

}}

export default NewCatProductForm
