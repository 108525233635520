import React, { Fragment } from 'react';
import NewCategoryProductForm from '../NewCategoryProductForm';

class NewCategory extends React.Component {
	render() {
		const data = {
			price: '',
			images: [],
			stock: '',
			commentary: ''
		};

		const name = '';
		const categories = this.props.history.location.state;

		return (
			<div style={{ borderRadius: '8px', paddingRight: '15px', paddingLeft: '15px', backgroundColor: 'white', paddingBottom: '30px'}}>
				<NewCategoryProductForm
					history={this.props.history}
					data={data}
					name={name}
					newCategory={true}
					newProduct={true}
					categories={categories}
				/>
			</div>
		);
	}
}

export default NewCategory;
