import { createStore } from "redux";
import Reducer from "./reducers";

const initialState = {
  user: '',
  category: ''
};

const store = createStore(Reducer, initialState);

export default store;