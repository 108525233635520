import React, { Component } from 'react';
import { auth, storage } from '../../firebase';

class ImageUpload extends Component {
	constructor(props) {
		super(props);

		const { url, name } = this.props;

		this.state = {
			image: null,
			url,
			progress: 0,
			name
		};
		this.handleChange = this.handleChange.bind(this);
		this.handleUpload = this.handleUpload.bind(this);
	}

	handleChange = (e) => {
		if (e.target.files[0]) {
			const image = e.target.files[0];
			this.setState({
				image
			});
		}
	};
	handleUpload = () => {
		const { id } = this.props;
		const { image } = this.state;
		const user_ = auth.currentUser.uid;

		// const uploadTask = storage.ref(`${user_}/${name}`).put(image);
		console.log('jaaja', this.props);
		let uploadTask;
		if (this.props.productID !== undefined) {
			uploadTask = storage.ref(`${user_}/${this.props.productID}/${id}`).put(image);
		} else {
			uploadTask = storage.ref(`${user_}/${id}`).put(image);
		}

		uploadTask.on(
			'state_changed',
			(snapshot) => {
				// progrss function ....
				const progress = Math.round(snapshot.bytesTransferred / snapshot.totalBytes * 100);
				this.setState({ progress });
			},
			(error) => {
				console.log(error);
			},
			() => {
				// complete function ....
				if (this.props.productID !== undefined) {
					storage.ref(user_).child(this.props.productID).child(id).getDownloadURL().then((url) => {
						this.props.onSelectUrl(url, id);
						this.setState({ url });
					});
				} else {
					storage.ref(user_).child(id).getDownloadURL().then((url) => {
						this.props.onSelectUrl(url, id);
						this.setState({ url });
					});
				}

				// // complete function ....
				// storage.ref(user_).child(name).getDownloadURL().then((url) => {
				// 	this.props.onSelectUrl(url);
				// 	this.setState({ url });
				// });
			}
		);
	};

	render() {
		return (
			<div>
				<div className="row justify-content-center align-items-center mb-3">
					<img
						src={this.props.url || 'http://via.placeholder.com/400x400'}
						alt="Uploaded images"
						height="250"
						width="250"
						style={{ borderRadius: 10 }}
					/>
				</div>
				<div className="row justify-content-center align-items-center mb-3">
					<progress value={this.state.progress} max="100" />
				</div>
				<div className="row mb-3">
					<div className="col-12 col-md-12">
						<input type="file" onChange={this.handleChange} />
					</div>

					<div className="col-12 col-md-12 mt-1">
						<button
							className="button"
							onClick={this.handleUpload}
						>
							Subir Imagen
						</button>
					</div>
				</div>
			</div>
		);
	}
}

export default ImageUpload;
