import React, { Fragment } from 'react';
import { auth, db } from '../../firebase';
import { connect } from 'react-redux';

import Loading from '../Extra/Loading';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './Products.css';
import { AiFillDelete, AiTwotoneEdit } from "react-icons/ai";
import { CgLock, CgPlayListAdd, CgInstagram } from "react-icons/cg";
import { BiMoveVertical } from "react-icons/bi";
import { HiOutlineHand } from "react-icons/hi";


const letterStyle = {
	fontSize: 13,
	fontWeight: 'bold',
}

const letterStyle2 = {
	fontSize: 20,
}

class Products extends React.Component {
	state = {
		categories: [],
		numbers: [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20 ],
		loading: true
	};

	async componentDidMount() {
		const { category } = this.props;
		const categories = [];

		auth.onAuthStateChanged(async (user) => {
			if (user) {
				const data = await db
					.collection('users')
					.doc(user.uid)
					.collection(category.name)
					.orderBy('priority', 'asc')
					.get();

				data.docs.forEach((doc) => {
					categories.push({
						id: doc.id,
						data: doc.data(),
						priority: doc.data().priority || 100
					});
				});

				this.setState({
					categories,
					loading: false
				});
			} else {
				console.log('no se pudo mntar');
			}
		});
	}

	saveOrder = async () => {
		
		
		const { category } = this.props;
		var user = auth.currentUser;

		console.log(this.state.categories, "jajajaj")

		if (user) {
			const promises = this.state.categories.map(async (product, index) => {
				console.log(product, index);
				await db
					.collection('users')
					.doc(user.uid)
					.collection(category.name)
					.doc(product.id)
					.set({ priority: index }, { merge: true });
			});

			await Promise.all(promises);

			this.props.history.push('/dashboard');
		} else {
			console.log('paso un problema');
		}
	};

	delete = (id) => {
		const { category } = this.props;
		const { categories } = this.state;
		const categories_ = categories.filter((category) => category.id !== id);
		var user_ = auth.currentUser;

		if (user_) {
			this.setState({
				categories: categories_
			});

			db
				.collection('users')
				.doc(user_.uid)
				.collection(category.name)
				.doc(id)
				.delete()
				.then(console.log('eliminado'))
				.catch((err) => {
					console.log(err.me);
				});
		} else {
			console.log('paso un problema');
		}
	};

	handleOnDragEnd = (result) => {
		if (!result.destination) return;
	
		const items = Array.from(this.state.categories);
		const [reorderedItem] = items.splice(result.source.index, 1);
		items.splice(result.destination.index, 0, reorderedItem);
		
		this.setState({categories: items})
	  }

	render() {
		const { categories } = this.state;
		const { history, category } = this.props;
		if(this.state.loading){
            return(
                <Loading/>
            )
		}

		return (
			<Fragment>

			<div className='pl-3 pb-3' style={{backgroundColor: 'white', borderRadius: '10px'}}>

				<div className="row pt-2 pb-4 mr-2 ml-2" style={{borderBottomStyle: 'solid', borderWidth: '1px', borderColor: 'lightGray'}}>
					<div className="col-md-12 col-12 pt-2 pb-2" style={letterStyle2}>
						{`Categoría > ${category.name}`} 
					</div>
					<div className="col-md-12 pt-2 pb-2">
					* Puedes cambiar la posición de los productos arrastrándolos y luego presionando "Guardar Cambios"
				</div>
				</div>
				



				<div className="row mr-2 ml-2 mb-2 pt-3 pb-3 align-items-center" style={{borderBottomStyle: 'solid', borderWidth: '1px', borderColor: 'lightGray'}}>
					<div className="col-md-2 col-5" style={letterStyle}>
						<BiMoveVertical/> Posición
					</div>
					<div className="col-md-3 col-3" style={letterStyle}>
						Nombre
					</div>
					<div className="d-none d-sm-block col-md-3 col-4" style={letterStyle}>
						Descripción
					</div>
					<div className="d-none d-sm-block col-md-2 col-3"  style={{ fontSize: 14, fontWeight: 'bold' }} >
						Última Modificación
					</div>
					<div className="col-md-4">
					</div>
				</div> 
					
				<DragDropContext onDragEnd={this.handleOnDragEnd}>
					<Droppable droppableId="characters">
						{(provided) => (
							<ul className="characters" {...provided.droppableProps} ref={provided.innerRef}>
								{categories.map(({id, data}, index) => {
									return (
									<Draggable 													
 										key={id} draggableId={id} index={index}>
										{(provided) => (
											<div className="row justify-content-center align-items-center characters-thumb pt-3 pb-3 mb-2 mr-2 ml-1" ref={provided.innerRef}  {...provided.draggableProps}  {...provided.dragHandleProps}>
												<div className="col-md-2 col-4" 
													style={{ fontSize: 14}}
												>
													<HiOutlineHand size={20} /> {index}
												</div>

												<div className="col-md-3 col-5"
													style={{ fontSize: 14}}
												>
													{data.name}
												</div>

												<div className="d-none d-sm-block col-md-3"
													style={{ fontSize: 14}}
												>
													{data.commentary}
												</div>

												<div className="d-none d-sm-block col-md-2"
													style={{ fontSize: 14}}
												>
													-
												</div>

												<div  className="col-md-1 col-1">
													<AiTwotoneEdit
														size={20}

														onClick={() =>
															history.push({
																pathname: `/dashboard/products/${id}`,
																state: { data: data, newCategory: false, newProduct: false }
															})}
														/>
												</div>
												<div className="col-md-1 col-1">
													<AiFillDelete
														size={20}

														onClick={(e) => {
															if (window.confirm('¿Seguro desde eliminar el producto?'))
																this.delete(id);
														}}
														/>
											
												</div>
											</div>
											
										)}
									</Draggable>
									);
								})}
								{provided.placeholder}
							</ul>
						)}
					</Droppable>
				</DragDropContext>

					
				<div className="row justify-content-end mt-5 mr-3">
					<button
						className="button mt-3 ml-3"
						onClick={() =>
							history.push({
								pathname: `addcategoryphoto`,
								state: { category }
							})}
					>
						<CgInstagram/> Cambiar Foto Categoría
					</button>
					<button
						className="button mt-3 ml-3"
						onClick={() =>
							history.push({
								pathname: `products/newproduct/f`
							})}
						
					>
						<CgPlayListAdd/> Agregar Nuevo Producto
					</button>
					<button
						className="button mt-3 ml-3"
						onClick={() => this.saveOrder()}
					>
						<CgLock/> Guardar Cambios
					</button>		
				</div>
			</div>
		</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.user,
	category: state.category
});

export default connect(mapStateToProps)(Products);