import React, { Fragment } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { useHistory, Route, Switch } from 'react-router-dom';
import Profile from './components/SideBar/Profile';
import Products from './components/Products/Products';
import EditProduct from './components/Products/EditProduct';
import NewProduct from './components/Products/NewProduct';
import LinkShop from './components/SideBar/LinkShop';
import NewCategory from './components/Categories/NewCategory';
import Delivery from './components/Delivery/Delivery'

import Categories from './components/Categories/Categories';
import Suggestions from './components/SideBar/Suggestions';
import Tutorials from './components/SideBar/Tutorials';
import Addcategoryphoto from './components/Extra/Photos';
import AddImages from './components/Extra/Images';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Login from './components/Login/Login';
import ResetPassword from './components/Login/ResetPassword';
import Support from './components/SideBar/Support';
import { CgFormatJustify, CgCloud, CgHeadset, CgProfile, CgShoppingBag, CgSmileMouthOpen, CgClapperBoard } from "react-icons/cg";
import PrivateRoute from './PrivateRoute';


function Copyright() {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			{'Copyright © SimpleQr '}
			
			{new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex'
	},
	toolbar: {
		paddingRight: 24 // keep right padding when drawer closed
	},
	toolbarIcon: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		padding: '0 8px',
		...theme.mixins.toolbar
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create([ 'width', 'margin' ], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	menuButton: {
		marginRight: 36
	},
	menuButtonHidden: {
		display: 'none'
	},
	title: {
		flexGrow: 1
	},
	drawerPaper: {
		position: 'relative',
		whiteSpace: 'nowrap',
		width: drawerWidth,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	},
	drawerPaperClose: {
		overflowX: 'hidden',
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		width: theme.spacing(7),
		[theme.breakpoints.up('sm')]: {
			width: theme.spacing(9)
		}
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: '100vh',
		overflow: 'auto'
	},
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4)
	},
	paper: {
		padding: theme.spacing(2),
		display: 'flex',
		overflow: 'auto',
		flexDirection: 'column'
	},
	fixedHeight: {
		height: 240
	}
}));

export default function Dashboard(props) {
	const classes = useStyles();
	const [ open, setOpen ] = React.useState(true);
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};

	let history = useHistory();
	
	return (
		<Switch>
			<Route exact path="/" component={Login} />
			<Route exact path="/password" component={ResetPassword} />
			<div className={classes.root} style={{backgroundColor: '#e5e5e5'}} >
				<CssBaseline />
				<AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
					<Toolbar className={classes.toolbar} style={{ backgroundColor: 'black' }}>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
						>
							<MenuIcon />
						</IconButton>
					</Toolbar>
				</AppBar>

			
				<Drawer
					variant="temporary"
					classes={{
						paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)
					}}
					open={open}
					onClick={handleDrawerClose}
				>	
					
						<div  className={classes.toolbarIcon}>
							<IconButton onClick={handleDrawerClose}>
								<ChevronLeftIcon />
							</IconButton>
						</div>
						{open === true ? 
							<Fragment >
								<Divider/>
									<List onClick={() => console.log("jja")} >
										<div >	
											<ListItem button onClick={() => {
												handleDrawerClose()
												history.push('/dashboard')} }>
											
												<CgFormatJustify size={30} />
													
												<ListItemText
													className="ml-4"
													primary="Categorías"
												/>
											</ListItem> 

											<ListItem button onClick={() => {
												handleDrawerClose()
												history.push('/dashboard/suggestion')} }>
											
												<CgSmileMouthOpen size={30} />
													
												<ListItemText
													className="ml-4"
													primary="Comentarios"
												/>
											</ListItem> 

											<ListItem button onClick={() => {
												handleDrawerClose()
												history.push('/dashboard/delivery')} }>
				
												<CgShoppingBag size={30} />
													
												<ListItemText
													className="ml-4"
													primary="Delivery"
												/>
											</ListItem>

											<ListItem button onClick={() => {
												handleDrawerClose()
												history.push('/dashboard/profile')} }>
												<CgProfile size={30} />
													
												<ListItemText
													className="ml-4"
													primary="Perfil"
												/>
											</ListItem>

											
											<ListItem button onClick={() => {
												handleDrawerClose()
												history.push('/dashboard/tutorials')} }>
												<CgClapperBoard size={30} />
													
												<ListItemText
													className="ml-4"
													primary="Tutoriales"
												/>
											</ListItem>
											
											<ListItem button onClick={() => {
												handleDrawerClose()
												history.push('/dashboard/support')} }>
											
												<CgHeadset size={30} />
													
												<ListItemText
													className="ml-4"
													primary="Soporte"
												/>
											</ListItem> 

											<ListItem button onClick={() => {
												handleDrawerClose()
												history.push('/dashboard/linkshop')} }>
											
												<CgCloud size={30} />
													
												<ListItemText
													className="ml-4"
													primary="Tu Sitio"
												/>
											</ListItem> 

										</div>
									</List> 
								<Divider /> 
							</Fragment>
							: ''}		
				</Drawer>

				<main className={classes.content}>
					<div className={classes.appBarSpacer} />
					<Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Route exact path="/dashboard" component={Categories} />
                <Route exact path="/dashboard/delivery" component={Delivery} />
                <Route exact path="/dashboard/support" component={Support} />
                <Route exact path="/dashboard/suggestion" component={Suggestions} />
                <Route exact path="/dashboard/profile" component={Profile} />
                <Route exact path="/dashboard/linkshop" component={LinkShop} />
                <Route exact path="/dashboard/tutorials" component={Tutorials} />
                <Route exact path="/dashboard/addcategoryphoto" component={Addcategoryphoto} />
                <Route exact path="/dashboard/products" component={Products} />
                <Route exact path="/dashboard/products/newproduct/f" component={NewProduct} />
                <Route exact path="/dashboard/products/:id" component={EditProduct} />
                <Route exact path="/dashboard/products/:id/images" component={AddImages} />
                <Route exact path="/dashboard/categories/newcategory" component={NewCategory} />
              </Grid>
            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
					</Container>
				</main>
			</div>
		</Switch>
	);
}
