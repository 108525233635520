export default (state, action) => {
	switch (action.type) {
		case 'ADD_USER':
			return {
				...state,
				user: action.user
			};

		case 'ADD_CATEGORY':
			return {
				...state,
				category: action.category
			};

		default:
			return state;
	}
};
