import React, { Fragment } from 'react';
import NewCategoryProductForm from '../NewCategoryProductForm';

class EditProduct extends React.Component {
	render() {
		const { price, price2, tag, commentary, name, stock, images, subCategories, toggleEspecification } = this.props.history.location.state.data;

		const data = {
			name,
			price,
			price2,
			tag,
			commentary,
			stock,
			images,
			subCategories,
			toggleEspecification
		};

		const id = this.props.match.params.id;

		return (
			<div style={{ borderRadius: '8px', paddingRight: '15px', paddingLeft: '15px', backgroundColor: 'white', paddingBottom: '30px'}}>
				<NewCategoryProductForm history={this.props.history} data={data} id={id} newCategory={false} newProduct={false} />
			</div>
		);
	}
}

export default EditProduct;
