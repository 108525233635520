import React, { Fragment } from 'react';
import { auth, db } from '../../firebase';
import { deliveryMap_, deliveryMap } from './data'
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import Loading from '../Extra/Loading';
import PhoneInput from 'react-phone-input-2'

const animatedComponents = makeAnimated();

export const delivery = [
    {value: 'regionMetropolitana', label: "Región Metropolitana"},
    {value: 'tarapaca', label: "Tarapacá"},
    {value: 'antofagasta', label: "Antofagasta"},
    {value: 'atacama', label: "Atacama"},
    {value: 'coquimbo', label: "Coquimbo"},
    {value: 'valparaiso', label: "Valparaíso"},
    {value: 'ohiggins', label: "O'Higgins"},
    {value: 'maule', label: "Maule"},
    {value: 'biobio', label: "Bio Bio"},
    {value: 'araucania', label: "Araucanía"},
    {value: 'losLagos', label: "Los Lagos"},
    {value: 'aysen', label: "Aysén"},
    {value: 'magallanes', label: "Magallanes"},
    {value: 'losRios', label: "Los Ríos"},
    {value: 'aricaParinacota', label: "Arica y Parinacota"},
]

 
class Orders extends React.Component {
	state = {
		delivery : {
        'regionMetropolitana': {price: 0, communes: []},
        'tarapaca': {price: 0, communes: []},
        'antofagasta': {price: 0, communes: []},
        'atacama': {price: 0, communes: []},
        'coquimbo': {price: 0, communes: []},
        'valparaiso': {price: 0, communes: []},
        'ohiggins': {price: 0, communes: []},
        'maule': {price: 0, communes: []},
        'biobio': {price: 0, communes: []},
        'araucania': {price: 0, communes: []},
        'losLagos': {price: 0, communes: []},
        'aysen': {price: 0, communes: []},
        'magallanes': {price: 0, communes: []},
        'losRios': {price: 0, communes: []},
        'aricaParinacota': {price: 0, communes: []},
    },
    loading: true,
    deliveryInformation: '',
    toggleWithdrawal: false,
    toggleDelivery: false,
    toggleWhatsAppMethod: false,
    shopDirection: '',
    dispatchTime: '',
    whatsapp: '',
	};

  componentDidMount = async () => {
      let deliveryAux = delivery
      auth.onAuthStateChanged(async (user) => {
        const data = await db.collection('users').doc(user.uid).get();
        deliveryAux = data.data().delivery || this.state.delivery
            
        this.setState({
            delivery: deliveryAux,
            loading: false,
            deliveryInformation: data.data().deliveryInformation || '',
            toggleWithdrawal: data.data().toggleWithdrawal || false,
            toggleDelivery: data.data().toggleDelivery || false,
            shopDirection: data.data().shopDirection || '',
            dispatchTime: data.data().dispatchTime || '',
            toggleWhatsAppMethod: data.data().toggleWhatsAppMethod || false,
            whatsapp: data.data().whatsapp || '',
        })
      })
      
  }

  changeCommune = (event, region) => {
      console.log("jajaj", event)

      const { delivery } = this.state

      delivery[region].communes = event

      if(delivery[region].communes === null){
          delivery[region].communes =[] 
      }
      this.setState({
          delivery
      })

  }
  
  changePrice = (e, region) => {
      const { delivery } = this.state

      delivery[region].price = e.target.value

      this.setState({
          delivery
      })

  }

  renderRegion = (region) => {
    return(
      <Fragment>
        <div className="row align-items-center justify-content-center mb-2 ml-2">
          <div className="col-md-3">
            <div className="row align-items-center" style={{fontSize: 15}}> 
              {deliveryMap_[region]} 
            </div>
            <div className="row align-items-center"> 
              {this.state.toggleWhatsAppMethod === true ? '' : 
              <Fragment>
                <div className="col-md-3">
                  Precio 
                </div>
                <div className="col-md-6">
                  <input
                      onChange={(e) => {
                          if (/^[0-9][0-9]*$/.test(e.target.value) ||
                          e.target.value === ''){
                              this.changePrice(e, region)
                          }	
                      }}
                      type="text"
                      name="price"
                      className="form-control mb-0 mt-0 pt-0 pb-0"
                      value={this.state.delivery[region].price}
                  />
                </div>
              </Fragment> } 
            </div>
          </div>
    
          <div className="col-md-9">
              <Select
                  closeMenuOnSelect={false}
                  components={animatedComponents}
                  defaultValue={this.state.delivery[region].communes}
                  isMulti
                  options={deliveryMap[region]}
                  onChange={(event) => this.changeCommune(event, region)}
              />
          </div>

        </div>        
      </Fragment>
    )}  

  changeState = (e) => {
    const { name, value } = e.target;
    this.setState({
        [name]: value
    });
  };

        
  changeBox = (e) => {
		let { toggleDelivery, toggleWithdrawal, toggleWhatsAppMethod} = this.state;
		const { name } = e.target;
        
		if(name === 'toggleDelivery'){
			this.setState({
        toggleDelivery: !toggleDelivery,
        toggleWhatsAppMethod: false
			});

		} else if(name === 'toggleWhatsAppMethod'){
			this.setState({
				toggleDelivery: false,
        toggleWhatsAppMethod: !toggleWhatsAppMethod       
      });
      
		} else if(name === 'toggleWithdrawal'){
			this.setState({
				toggleWithdrawal: !toggleWithdrawal,
			});
		} 
  };
    
	submitForm = async (e) => {
        var user_ = auth.currentUser;
        console.log(this.state)
		if (user_) {
			await db.collection('users').doc(user_.uid).set(
				{
          delivery: this.state.delivery,
          deliveryInformation: this.state.deliveryInformation,
          toggleWithdrawal: this.state.toggleWithdrawal ,
          toggleDelivery: this.state.toggleDelivery,
          shopDirection: this.state.shopDirection,
          dispatchTime: this.state.dispatchTime,
          toggleWhatsAppMethod: this.state.toggleWhatsAppMethod,
          whatsapp: this.state.whatsapp
                },
				{ merge: true }
			);

			this.props.history.push('/dashboard');
		} else {
			console.log('ERROR');
		}
	};
    
  displayDeliveryCommunes = () => (
    <Fragment>
      {this.state.toggleWhatsAppMethod === true ? '' : 
        <div className="form-group">
            <label> Ingrese tiempos de despacho </label>
            <textarea
                type="text"
                name="deliveryInformation"
                className="form-control"
                placeholder="Los productos son entregados entre 2-3 días hábiles en Santiago. Los envíos a región se demoran entre 5-6 días hábiles."
                defaultValue={this.state.deliveryInformation}
                onChange={this.changeState}
            />
        </div>
      }

      {this.renderRegion("regionMetropolitana")}
      {this.renderRegion("tarapaca")}
      {this.renderRegion("antofagasta")}
      {this.renderRegion("coquimbo")}
      {this.renderRegion("valparaiso")}
      {this.renderRegion("ohiggins")}
      {this.renderRegion("maule")}
      {this.renderRegion("biobio")}
      {this.renderRegion("araucania")}
      {this.renderRegion("losLagos")}
      {this.renderRegion("aysen")}
      {this.renderRegion("magallanes")}
      {this.renderRegion("losRios")}
      {this.renderRegion("aricaParinacota")}
    </Fragment>
  )

	render() {
    const {toggleDelivery, toggleWithdrawal, shopDirection, deliveryInformation, whatsapp } = this.state
    let disabled = false;

    if(whatsapp !== ''){
      if(toggleWithdrawal === true && shopDirection === ''){
          disabled = true
      } 
      else if(toggleDelivery === false && deliveryInformation !== ''){
          disabled = false
      }
    }

    if(this.state.loading){
      return(
          <Loading/>
      )
    }

    return (
			<div className='pl-3 pb-3 pt-4' style={{backgroundColor: 'white', borderRadius: '10px'}}>
        <div style={{fontSize: 27, fontFamily: 'Poppins'}}  className="row justify-content-center">
            Pedidos por WhatsApp
        </div>

        <div style={{fontSize: 17, fontFamily: 'Poppins'}}  className="row justify-content-center mt-4 mb-4">
            <div style={{fontSize: 17, fontFamily: 'Poppins'}}  className="col-md-6">
                Esta sección te permite activar el pedido por WhatsApp, configurar costos de despacho y zona a cual puedes despachar. Si no activas ninguna de ellas, tu tienda seguirá modo catálogo. Recuerda que  esta alternativa no tiene ningún costo adicional. Te recomendamos activar a lo menos una de ellas.
            </div>
        </div>

        <div className="form-group mt-3 mb-4">
          <label>Whatsapp</label>
          <PhoneInput
              value={whatsapp}
              country={'cl'}
              onChange={whatsapp => this.setState({whatsapp})}
              enableSearch={true}
              preferredCountries={['cl']}
          />
          <p style={{fontSize: 13}}>El número es necesario para recibir pedidos</p>
        </div>

        <hr className="mt-5 mb-5" />

        <h4> Activar Retiro en Tienda </h4>

        <div className="row align-items-center mt-1 mb-1 ml-1">
            <input
                className="form-check mr-3"
                type="checkbox"
                name="toggleWithdrawal"
                checked={this.state.toggleWithdrawal}
                onChange={this.changeBox}
            />

            <div className="mr-3" style={{ fontSize: 18 }}> Activar Retiro Tienda </div>                             
        </div>
        
        <p className="mr-3" style={{ fontSize: 13 }}>  Llegará el pedido completo por WhatsApp, el cliente irá a retirar el pedido a la tienda </p>

        {this.state.toggleWithdrawal === true ? 
          <Fragment>
            <div className="form-group">
                <label> Dirección de la Tienda </label>
                <textarea
                    type="text"
                    name="shopDirection"
                    className="form-control"
                    placeholder="Avenida del Mar 1424, Maitencillo"
                    defaultValue={this.state.shopDirection}
                    onChange={this.changeState}
                />
            </div>

            <div className="form-group">
                <label> Agregue información extra sobre el pedido (Tiempos, Horarios, etc) </label>
                <textarea
                    type="text"
                    name="dispatchTime"
                    className="form-control"
                    placeholder="El retiro se coordinara por whatsapp"
                    defaultValue={this.state.dispatchTime}
                    onChange={this.changeState}
                />
            </div>
          </Fragment> : 
        ''
        }

        <hr className="mt-5 mb-5" />

        <h4> Activar Delivery 
          <span style={{fontSize:14}}> 
            (Seleccionar una de las dos opciones) 
          </span> 
        </h4>
            
        <div className="row align-items-center mb-1 ml-1">
            <input
                className="form-check mr-3"
                type="checkbox"
                name="toggleDelivery"
                checked={this.state.toggleDelivery}
                onChange={this.changeBox}
            />

            <div className="mr-3" style={{ fontSize: 18 }}> 
              Activar Despacho a Domicilio
            </div>
        </div>

        <p className="mr-3" style={{ fontSize: 13 }}> 
          Llegará el pedido completo por WhatsApp, con dirección de entrega y costos asociados. Se debe configurar la zona de despacho y sus precios 
        </p>

        {this.state.toggleDelivery === true ? this.displayDeliveryCommunes() : ''}  

        <div className="row align-items-center mt-4 mb-1 ml-1">
          <input
            className="form-check mr-3"
            type="checkbox"
            name="toggleWhatsAppMethod"
            checked={this.state.toggleWhatsAppMethod}
            onChange={this.changeBox}
          />
          <div className="mr-3" style={{ fontSize: 18 }}> Activar Pedido Simple </div>  
        </div> 

        <p className="mr-3" style={{ fontSize: 13 }}> Llegará el pedido por WhatsApp, todo lo relacionado con la entrega del producto se coordina directamente con el cliente vía WhatsApp u otro  </p>

        {this.state.toggleWhatsAppMethod === true ? this.displayDeliveryCommunes() : ''}

        <div className="row justify-content-end mr-4">
          <button 
            className="button" 
            onClick={() => this.submitForm()}
            disabled={disabled}> 
              Guardar Cambios
          </button>
        </div>

			</div>
		);
	}
}

export default Orders;