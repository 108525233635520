import React from 'react';
import { auth } from '../../firebase';
import logo from '../../images/simple.png';

class ResetPassword extends React.Component {
	state = {
		email: '',
		err: '',
		message: '',
		send: false
	};

	updateState = (e) => {
		const { name, value } = e.target;
		this.setState({
			[name]: value
		});
	};

	resetPassword = async (event, email) => {
		event.preventDefault();
		auth
			.sendPasswordResetEmail(email)
			.then(
				() =>
					this.setState({
						message: 'Se ha enviado un correo electrónico',
						send: true
					}),
				setTimeout(() => this.props.history.push('/'), 3000)
			)
			.catch(function(error) {
				console.log('error');
			});
	};

	render() {
		const { email, err } = this.state;

		const alert_ = err !== '' ? `${err.message}` : this.state.message;
		return (
			<div className="container">
				{alert_}

				<div className="row mt-3">
					<button
						className="btn"
						style={{ backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}
						onClick={() => this.props.history.push('/')}
					>
						Volver
					</button>
				</div>

				<div className="row justify-content-center mr-4">
					<img src={logo} alt="Logo" style={{ maxWidth: '30%', height: '350px' }} />
				</div>

				<div className="col-md-4 offset-md-4 mb-2">					
					Correo Electrónico
				</div>

				<div className="col-md-4 offset-md-4 mb-2">
          <input
            type="email"
            name="email"
            className="form-control mb-4"
            placeholder="hola@example.com"
            styles={{ width: '50%' }}
            onChange={this.updateState}
          />
				</div>

				<div className="row justify-content-center">
					<button
						className="btn"
						style={{ backgroundColor: 'black', color: 'white', fontWeight: 'bold' }}
						onClick={(event) => this.resetPassword(event, email)}
						disabled={this.state.send || !this.state.email}
					>
						Resetear Contraseña
					</button>
				</div>
			</div>
		);
	}
}

export default ResetPassword;
